import React from 'react'
import styled from 'styled-components'

import Paragraph from './Paragraph'

const StyledLi = styled.li`
  ${({ theme: { spacing } }) => `
    margin-bottom: ${spacing(1)}px;
  `}
`

const ListItemContent = styled(Paragraph).attrs({
  component: 'span',
})``

const ListItem: React.FC<ListItem> = ({ children, ...rest }: ListItem) => {
  return (
    <StyledLi {...rest}>
      <ListItemContent>{children}</ListItemContent>
    </StyledLi>
  )
}

interface ListItem {
  children: React.ReactNode & React.ReactNode[]
}

export default ListItem
