import styled from 'styled-components'

const List = styled.ul<List>`
  word-break: break-word;
  ${({ theme: { palette, spacing } }) => `
    color: ${palette.text.primary};
    margin: ${spacing(4)}px 0;
  `}
`

interface List {
  children: React.ReactNode & React.ReactNode[]
}

export default List
