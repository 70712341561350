import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import Paragraph from './Paragraph'

import { ExternalLink } from 'styles'

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  p:first-child {
    margin-bottom: 12px;
  }
`

const imgStyle = {
  borderRadius: '50%',
  marginRight: '24px',
  marginTop: '12px',
  height: '96px',
  width: '96px',
}

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: -12px;

  ${TextContainer} {
    margin-top: 12px;
  }
`

const Bio = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            github
            company
            twitter
            twitter_user
          }
        }
      }
    `
  )
  const { github, twitter, twitter_user, company } = site.siteMetadata

  return (
    <Container {...props}>
      <StaticImage
        src="../images/bio.webp"
        alt="Alexandre Le Lain"
        layout="constrained"
        width={356}
        height={356}
        placeholder="blurred"
        style={imgStyle}
      />
      <TextContainer>
        <Paragraph>
          <b>
            Alexandre Le Lain &#8249;<ExternalLink href={twitter}>{twitter_user}</ExternalLink>
            &#x203A;
          </b>
        </Paragraph>
        <Paragraph>
          Software Engineer at <ExternalLink href={company}>HelloWork</ExternalLink>. I write code
          to fix my code.
        </Paragraph>
        <Paragraph>
          You can check out my <ExternalLink href={github}>Github</ExternalLink> anytime.
        </Paragraph>
      </TextContainer>
    </Container>
  )
}

export default styled(Bio)``
