import React from 'react'

import InlineCode from './InlineCode'

const Code: React.FC<CodeProps> = ({ inline, ...rest }: CodeProps) => {
  return inline ? <InlineCode {...rest} /> : <code {...rest} />
}

interface CodeProps {
  inline?: boolean
}

export default Code
